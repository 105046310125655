import React, { useEffect, useRef } from "react";
import Avatar from "../../../assets/images/Avatar.png";
import { MessageSuggestions } from "./MessageSuggestions";

export const MessageList = ({ messages, handleSendMessage }) => {
  const messagesEndRef = useRef(null);

  // Scroll to the bottom when messages change
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  return (
    <div className="flex flex-col flex-grow gap-4 overflow-y-auto rounded-xl rounded-b-none py-4 px-4 max-h-[80vh]">
      {messages.map((message, index) => (
        <div key={index} className="flex">
          {message.role === "assistant" && (
            <img
              src={Avatar}
              alt="AI-Klajdi Avatar"
              width="35"
              height="35"
              className="mr-2 mb-5 self-end"
            />
          )}
          <div className="flex flex-col gap-1 w-full">
            <div
              className={`${
                message.role === "assistant"
                  ? "justify-self-start bg-white text-black w-fit"
                  : "ml-auto bg-black text-white"
              } p-2 rounded-xl`}
            >
              {message.content}
            </div>
            {message.role === "assistant" && (
              <div className="text-black/70 text-xs ml-2">AI-Klajdi</div>
            )}
          </div>
        </div>
      ))}
      {/* Invisible div to auto-scroll to the bottom */}
      <div ref={messagesEndRef} />
      <MessageSuggestions handleSendMessage={handleSendMessage} />
    </div>
  );
};

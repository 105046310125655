import React from "react";
import { Projects } from "./components/Projects";
import { Informations } from "./components/Informations";
import { Header } from "./components/Header";
import useTheme from "./hooks/useTheme";

export const Home = () => {
  const { theme, toggleTheme } = useTheme();

  return (
    <div
      className={`bg-[url("assets/images/day.webp")] dark:bg-[url("assets/images/night.webp")] transition-all duration-500 bg-cover bg-center bg-no-repeat min-h-screen lg:h-[100vh] py-6 text-white dark:text-black flex flex-col lg:flex-row  gap-8 lg:gap-4 overflow-y-hidden`}
    >
      <div className="flex flex-col gap-2 lg:gap-8 lg:w-3/5">
        <Header theme={theme} toggleTheme={toggleTheme} />
        <Informations theme={theme} />
      </div>
      <div className="ml-4 lg:ml-0 lg:w-2/5">
        <Projects />
        <div className="font-SequelSansLightHead text-xs w-full text-center lg:text-right lg:pr-4 text-white mt-2 lg:mt-2 ">
          Copyright © 2024 Klajdi Beqiraj. All right reserved.
        </div>
      </div>
    </div>
  );
};

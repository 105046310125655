import React from "react";
import GithubIconWhite from "../../assets/images/githubWhite.svg";
import LinkIcon from "../../assets/images/link.svg";
import { handleGithubClick } from "./helper";

export const ProjectLinks = ({ project }) => {
  return (
    <div className="flex items-center gap-4">
      {project.gitB !== undefined || project.gitF !== undefined ? (
        <div onClick={() => handleGithubClick(project?.gitF, project?.gitB)}>
          <img
            src={GithubIconWhite}
            alt="GitHub Icon"
            width="35"
            height="35"
            className="ml-2 hover:scale-125 transition-transform duration-300 cursor-pointer"
          />
        </div>
      ) : (
        ""
      )}
      {project.link ? (
        <img
          onClick={() => window.open(project.link, "_blank")}
          src={LinkIcon}
          alt="Link Icon"
          width="25"
          height="25"
          className="ml-2 hover:scale-125 transition-transform duration-300 cursor-pointer"
        />
      ) : (
        ""
      )}
    </div>
  );
};

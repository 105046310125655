import React from "react";
import { Presentation } from "./header/Presentation";
import { Contacts } from "./header/Contacts";

export const Header = ({ theme, toggleTheme }) => {
  return (
    <div className="flex flex-col gap-4">
      <div className="mr-4 ">
        <Presentation theme={theme} toggleTheme={toggleTheme} />
      </div>
      <div className=" flex justify-end ml-4 lg:ml-0 lg:mr-4">
        <Contacts theme={theme} />
      </div>
    </div>
  );
};

import React, { useState, useEffect } from "react";
import { projects } from "../assets/content/projects";
import { Project } from "./Projects/Project";

export const Projects = () => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const incrementCounter = () => {
      if (count < projects.length) {
        setCount((prevCount) => prevCount + 1);
      }
    };

    const interval = setInterval(incrementCounter, 100);

    return () => clearInterval(interval);
  }, [count]);

  return (
    <div className="w-full py-4 lg:pt-6 rounded-3xl rounded-r-none bg-gradient-to-r from-black/70 to-black/10 dark:from-white/70 dark:to-white/25">
      <div className="flex justify-between pb-4 lg:pb-6 pl-6 pr-10 font-SequelSansBoldDisp text-3xl lg:text-4xl border-b dark:border-black">
        <span className="">Projects</span>
        <span>{count}+</span>
      </div>
      <div className="flex flex-col gap-4 pt-4 ml-4 lg:pt-6 lg:ml-6  overflow-y-scroll h-[30em] lg:h-[80vh] rounded-b-xl">
        {projects.map((project, index) => (
          <Project project={project} key={index} />
        ))}
      </div>
    </div>
  );
};

export const handleGithubClick = (link1, link2) => {
  if (link2) {
    const newWindow = window.open("", "_blank");

    newWindow.document.write(`
      <html>
        <head>
          <title>GitHub Links</title>
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <style>
            body {
              font-family: 'Arial', sans-serif;
              background-color: #282c34;
              color: #fff;
              margin: 0;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              min-height: 80vh;
            }
            a {
              display: inline-block;
              margin: 30px;
              padding: 50px 70px;
              text-decoration: none;
              color: #fff;
              background-color: #61dafb;
              border-radius: 8px;
              font-size: 30px;
              transition: background-color 0.3s ease-in-out;
            }
            a:hover {
              background-color: #4fa3d1;
            }
          </style>
        </head>
        <body>
          <a href="${link1}" target="_blank">Frontend</a>
          <a href="${link2}" target="_blank">Backend</a>
        </body>
      </html>
    `);

    newWindow.document.close();
  } else {
    window.open(link1, "_blank");
  }
};

import React, { useState } from "react";
import { ProjectLinks } from "./ProjectLinks";
import "../../App.css";

export const Project = ({ project }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleMoreInfoClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div
      style={{ backgroundImage: `url(${project.image})` }}
      className={`relative rounded-xl rounded-r-none bg-cover bg-center border-l border-white  transform transition-all duration-200 `}
    >
      <div
        className={`flex justify-between absolute top-0 left-0 w-full h-full bg-black ${
          isExpanded ? "opacity-80" : "opacity-60"
        } rounded-xl rounded-r-none`}
      ></div>

      <div
        className={`flex flex-col pt-7 align-top justify-top z-10 relative text-white transform transition-all duration-500 ${
          isExpanded ? " lg:h-96 pb-12 h-[25em]" : "h-24"
        }`}
      >
        <div className=" flex justify-between w-full px-4">
          <span className=" font-SequelSansBoldDisp text-2xl">
            {project.name}
          </span>

          <ProjectLinks project={project} />
        </div>
        {isExpanded && (
          <div className="pt-4 mt-4 transition-all duration-500 px-4 flex flex-col border-t border-white gap-4">
            <div>
              <div className="text-xl font-semibold ">Description</div>
              <div className="text-sm mt-1">{project.description}</div>
            </div>
            <div>
              <div className="text-xl font-semibold">Role</div>
              <div className="text-sm mt-1">{project.role}</div>
            </div>
            <div>
              <div className="text-xl font-semibold">Technologies</div>
              <div className="flex flex-wrap gap-2 text-sm mt-1">
                {project.technology.map((tech) => (
                  <div key={tech.name} className="flex items-center gap-1">
                    <img src={tech.icon} alt={tech.name} className="w-4 h-4" />
                    <span>{tech.name}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        <button
          className="absolute bottom-2 left-4 text-white/90 hover:underline hover:text-white cursor-pointer text-sm"
          onClick={handleMoreInfoClick}
        >
          {isExpanded ? "Less Info" : "More Info"}
        </button>
      </div>
    </div>
  );
};

import React from "react";
const messageSuggestions = [
  "Background",
  "Skills",
  "Experience",
  "Personal Life",
];

export const MessageSuggestions = ({ handleSendMessage }) => {
  return (
    <div className="flex flex-wrap gap-2 pl-10">
      {messageSuggestions.map((suggestion, index) => (
        <div
          key={index}
          className="dark:bg-white/60 bg-white/60 text-black py-1 px-2 rounded-xl hover:bg-white/80 cursor-pointer"
          onClick={() => handleSendMessage(suggestion)}
        >
          {suggestion}
        </div>
      ))}
    </div>
  );
};

import React from "react";
import PhoneIconBlack from "../../assets/images/phoneBlack.svg";
import PhoneIconWhite from "../../assets/images/phoneWhite.svg";
import MailIconBlack from "../../assets/images/mailBlack.svg";
import MailIconWhite from "../../assets/images/mailWhite.svg";
import PinIconWhite from "../../assets/images/pinWhite.svg";
import PinIconBlack from "../../assets/images/pinBlack.svg";

export const Contacts = ({ theme }) => {
  const phoneNumber = "+46(0)737264701";
  const emailAddress = "klajdibeqiraj88@gmail.com";
  const address = "Uppsala, Sweden";

  const handlePinClick = () => {
    const latitude = 59.8586;
    const longitude = 17.6389;
    const googleMapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
    window.open(googleMapsUrl, "_blank");
  };
  const handlePhoneClick = () => {
    window.location.href = `tel:${phoneNumber}`;
  };

  const handleEmailClick = () => {
    window.location.href = `mailto:${emailAddress}`;
  };

  return (
    <div className="font-SequelSansMediumBody text-xs lg:text-base h-16  flex items-center justify-around gap-4 w-full py-4 px-6 rounded-3xl rounded-r-none lg:rounded-r-3xl lg:rounded-l-none bg-gradient-to-r lg:bg-gradient-to-l from-black/70 to-black/10 dark:from-white/70 dark:to-white/25">
      <div
        className="hidden sm:flex items-center gap-3 cursor-pointer"
        onClick={handlePinClick}
      >
        {theme === "dark" ? (
          <img src={PinIconBlack} alt="Pin Icon" width="15" height="15" />
        ) : (
          <img src={PinIconWhite} alt="Pin Icon" width="15" height="15" />
        )}
        <span className="  hover:underline underline-offset-4 ">{address}</span>
      </div>
      <div
        className="flex items-center gap-1 sm:gap-3 cursor-pointer"
        onClick={handlePhoneClick}
      >
        {theme === "dark" ? (
          <img src={PhoneIconBlack} alt="Phone Icon" width="25" height="25" />
        ) : (
          <img src={PhoneIconWhite} alt="Phone Icon" width="25" height="25" />
        )}
        <span className=" hover:underline underline-offset-4">
          {phoneNumber}
        </span>
      </div>
      <div
        className="flex items-center gap-1 sm:gap-3 cursor-pointer"
        onClick={handleEmailClick}
      >
        {theme === "dark" ? (
          <img src={MailIconBlack} alt="Mail Icon" width="25" height="25" />
        ) : (
          <img src={MailIconWhite} alt="Mail Icon" width="25" height="25" />
        )}
        <span className=" hover:underline underline-offset-4">
          {emailAddress}
        </span>
      </div>
    </div>
  );
};

import React from "react";
import Avatar from "../../assets/images/Avatar.png";

export const ChatCover = ({ onStartChat }) => (
  <div className="w-full flex flex-col justify-between items-center  gap-4 py-4 px-4 rounded-3xl bg-gradient-to-b from-black/70 to-black/70 dark:from-white/70 dark:via-transparent via-transparent dark:to-white/70 ">
    <h1 className="text-3xl  mb-4 font-SequelSansBoldDisp">AI-Klajdi</h1>
    <img
      src={Avatar}
      alt="AI-Klajdi Avatar"
      className="w-[200px] h-[200px] lg:w-[235px] lg:h-[235px]"
    />
    <div>
      <p className=" mb-8  text-center font-SequelSansMediumBody">
        Welcome to my personal page!
        <br /> Tap the button below to start the chat.
      </p>
    </div>
    <button
      onClick={onStartChat}
      className="p-4 bg-black bg-opacity-70 hover:bg-opacity-100 text-white rounded-xl focus:outline-none w-full transition-colors duration-300"
    >
      Start!
    </button>
  </div>
);

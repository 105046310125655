import React from "react";
import { Socials } from "./links/Socials";
import { Curriculum } from "./links/Curriculum";
import { Quotes } from "./links/Quotes";

export const Links = ({ theme }) => {
  return (
    <div className="flex flex-col gap-4 mr-4 lg:mr-0 lg:w-7/12 lg:mb-2">
      <Socials theme={theme} />
      <Curriculum theme={theme} />
      <Quotes theme={theme} />
    </div>
  );
};

import React from "react";
import CVIconBlack from "../../../assets/images/CVBlack.svg";
import CVIconWhite from "../../../assets/images/CVWhite.svg";
import CV_PDF from "../../../assets/CV-7.pdf";

export const Curriculum = ({ theme }) => {
  const pdfLink = CV_PDF;

  return (
    <a
      className="font-SequelSansMediumBody  h-16 lg:h-24 flex items-center justify-end pr-12 gap-10 lg:gap-5 w-full py-4 px-6 rounded-3xl rounded-l-none bg-gradient-to-l from-black/70 to-black/10 dark:from-white/70 dark:to-white/25 lg:dark:from-white/50 lg:dark:to-white/10 lg:hover:dark:from-white/70 lg:hover:dark:to-white/20 transition-transform duration-300"
      href={pdfLink}
      target="_blank"
      rel="noreferrer"
    >
      <span className="md:text-xs lg:text-base"> Click to view my CV</span>
      {theme === "dark" ? (
        <img src={CVIconBlack} alt="Phone Icon" width="35" height="35" />
      ) : (
        <img src={CVIconWhite} alt="Phone Icon" width="35" height="35" />
      )}
    </a>
  );
};

import { ChatOpenAI } from "@langchain/openai";
import { ChatPromptTemplate } from "@langchain/core/prompts";

export const chatWithOpenAI = async (input) => {
  const chatModel = new ChatOpenAI({
    openAIApiKey: process.env.REACT_APP_OPENAI_API_KEY,
  });

  let context = "";
  switch (input.toLowerCase()) {
    case "skills":
      context =
        "Soft skills: communication, flexibiliy, adaptablity, stress resilient, respectful, calm and collected. Technological skills: BackendBacked: Java - SpringBoot, Javascript - ExpressJS. Frontend: JavaScript, Typescript - React, React Native/Expo, Tailwind. Design: Figma";
      break;
    case "background":
      context =
        "I have a backround in literature and philosophy. I learned how to develop thank a bootcamp at SALT (school of applied technology) and many real life projects I took part of.";
      break;
    case "personal life":
      context =
        "I like nature, music, art and sports. One of my biggest passion is travelling, there is nothing better then exploring new cultures and ways of living.";
      break;
    case "experience":
      context =
        "I've worked as language teacher and translator previously. Then I moved into software development where I worked in several projects as freelancer with the role of fullstack developer and designer. Check them out I've listed them all!";
      break;
    default:
      context = "Default context";
      break;
  }

  const prompt =
    ChatPromptTemplate.fromTemplate(`Answer the following question based only on the provided context:
    <<<
    {context}
    >>>
    Question: {input}`);

  const chain = prompt.pipe(chatModel);
  const response = await chain.invoke({
    input: input,
    context: context,
  });
  return response.content;
};

import React, { useState } from "react";
import { MessageList } from "./chat/MessageList";
import { MessageInput } from "./chat/MessageInput";
import { chatWithOpenAI } from "./chatUtils";

export const Chat = () => {
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([
    {
      content:
        "Hi there! I'm AI-Klajdi, your virtual assistant. How can I help you today? ",
      role: "assistant",
    },
    {
      content:
        "To get started, here are a few topics you might be interested in:",
      role: "assistant",
    },
  ]);

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSendMessage = (text) => {
    const userMessage = { role: "user", content: text };
    setMessages((prevMessages) => [...prevMessages, userMessage]);

    chatWithOpenAI(text).then((aiResponse) => {
      setMessages((prevMessages) => [
        ...prevMessages,
        { role: "assistant", content: aiResponse },
      ]);
    });
    setMessage("");
  };

  return (
    <div className="w-full flex flex-col items-stretch  rounded-3xl bg-gradient-to-b from-black/70 to-black/70 dark:from-white/70 dark:via-white/10 via-transparent dark:to-white/70 h-[30em] lg:h-full">
      <MessageList messages={messages} handleSendMessage={handleSendMessage} />
      <MessageInput
        message={message}
        handleMessageChange={handleMessageChange}
        handleSendMessage={handleSendMessage}
      />
    </div>
  );
};

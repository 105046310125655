import React from "react";
import InstagramIconWhite from "../../../assets/images/instagramWhite.svg";
import InstagramIconBlack from "../../../assets/images/instagramBlack.svg";
import GithubIconWhite from "../../../assets/images/githubWhite.svg";
import GithubIconBlack from "../../../assets/images/githubBlack.svg";
import LinkedinIconBlack from "../../../assets/images/linkedinBlack.svg";
import LinkedinIconWhite from "../../../assets/images/linkedinWhite.svg";

export const Socials = ({ theme }) => {
  const socialLinks = [
    {
      icon: theme === "light" ? InstagramIconWhite : InstagramIconBlack,
      link: "https://www.instagram.com/klabeq/",
      size: 30,
    },
    {
      icon: theme === "light" ? GithubIconWhite : GithubIconBlack,
      link: "https://github.com/1998KB",
      size: 30,
    },
    {
      icon: theme === "light" ? LinkedinIconWhite : LinkedinIconBlack,
      link: "https://www.linkedin.com/in/klajdi-beqiraj-1998-leven/",
      size: 30,
    },
  ];

  return (
    <div className="flex justify-around items-center h-16 lg:h-24 w-full px-8 p-2 rounded-3xl rounded-l-none bg-gradient-to-l from-black/70 to-black/10 dark:from-white/70 dark:to-white/30 lg:dark:from-white/50 lg:dark:to-white/10">
      {socialLinks.map((social, index) => (
        <a
          key={index}
          href={social.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={social.icon}
            alt={`Social Icon ${index + 1}`}
            width={social.size}
            height={social.size}
            className="hover:scale-125 transition-transform duration-300"
          />
        </a>
      ))}
    </div>
  );
};

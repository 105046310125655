import project1 from "../images/project1.jpg";
import project2 from "../images/project2.jpeg";
import project3 from "../images/project3.jpeg";
import project4 from "../images/project4.jpeg";
import project5 from "../images/project5.jpg";
import project6 from "../images/project6.jpg";
import project7 from "../images/project7.jpg";
import project8 from "../images/project8.jpeg";
import project9 from "../images/project9.png";
import project10 from "../images/project10.jpeg";
import project11 from "../images/project11.jpg";
import project12 from "../images/project12.jpg";
import project13 from "../images/project13.jpg";
import project14 from "../images/project14.png";
import project15 from "../images/project15.png";
import project16 from "../images/project16.png";
import project17 from "../images/project17.jpg";
import project18 from "../images/project18.jpg";
import project19 from "../images/project19.jpg";
import project20 from "../images/project20.jpg";
import Typescript from "../images/techIcons/typescript.png";
import Expo from "../images/techIcons/expo.png";
import Tailwind from "../images/techIcons/tailwind.png";
import Native from "../images/techIcons/reactnative.png";
import React from "../images/techIcons/react.png";
import Firebase from "../images/techIcons/firebase.png";
import Express from "../images/techIcons/express.png";
import Figma from "../images/techIcons/figma.png";
import Java from "../images/techIcons/java.png";
import Azure from "../images/techIcons/azure.png";
import Spline from "../images/techIcons/spline.png";
import Springboot from "../images/techIcons/springboot.png";
import Node from "../images/techIcons/node.png";
import Mui from "../images/techIcons/mui.png";
import SQL from "../images/techIcons/sql.png";
import Css from "../images/techIcons/css.png";
import NoSQL from "../images/techIcons/NoSQL.png";

export const projects = [
  {
    name: "NordicPro",
    role: "Frontend Developer & Designer",
    description:
      "A specialized football training platform with booking features and showcasing experties.",
    image: project20,
    technology: [
      { name: "React", icon: React },
      { name: "TypeScript", icon: Typescript },
      { name: "Firebase", icon: Firebase },
      { name: "Node", icon: Node },
      { name: "Tailwind CSS", icon: Tailwind },
    ],
    link: "https://nordicpro.se",
  },
  {
    name: "Kad Bygg",
    role: "Frontend Developer & Designer",
    description:
      "A sleek website for a construction company, showcasing projects and allowing clients to book consultations.",
    image: project19,
    technology: [
      { name: "React", icon: React },
      { name: "TypeScript", icon: Typescript },
      { name: "Node", icon: Node },
      { name: "Tailwind CSS", icon: Tailwind },
      { name: "Firebase", icon: Firebase },
    ],
    link: "https://kadbygg.se",
  },
  {
    name: "ViviLife Landing Page",
    role: "Full-Stack Developer & Designer",
    description:
      "A sleek, responsive landing page for a health and wellness phone app, featuring app highlights, user testimonials, and a request invite.",
    image: project18,
    technology: [
      { name: "React", icon: React },
      { name: "TypeScript", icon: Typescript },
      { name: "Tailwind CSS", icon: Tailwind },
      { name: "Firebase", icon: Firebase },
      { name: "Node", icon: Node },
      { name: "NoSQL", icon: NoSQL },
    ],
    link: "https://vivilife.me",
  },

  {
    name: "ViviLife App",
    role: "Full-Stack Developer & Designer",
    description:
      "A health and wellness booking platform allowing users to schedule fitness classes and wellness sessions.",
    image: project17,
    technology: [
      { name: "Expo", icon: Expo },
      { name: "TypeScript", icon: Typescript },
      { name: "Tailwind CSS", icon: Tailwind },
      { name: "Firebase", icon: Firebase },
      { name: "Node", icon: Node },
      { name: "NoSQL", icon: NoSQL },
    ],
    gitF: "https://github.com/1998KB/vivilife_expo",
  },
  {
    name: "Ip Recognition ",
    role: "Full-Stack Developer & Designer",
    description:
      "Ip recognition on page load, google map integration, filters and analitics.",
    image: project13,
    technology: [
      { name: "TypeScript", icon: Typescript },
      { name: "Express", icon: Express },
      { name: "Node", icon: Node },
      { name: "NoSQL", icon: NoSQL },
      { name: "React", icon: React },
      { name: "Firebase", icon: Firebase },
    ],
    gitF: "https://github.com/1998KB/skyqraft-full-stack",
    link: "https://skyqraft-61cdf.web.app",
  },
  {
    name: "AI Chatbots App",
    role: "Mobile App Developer",
    description:
      "Android & IOS phone application. Three different chatbot AI-powered, speech-to-text and text-to-speech integration.",
    image: project8,
    technology: [
      { name: "Expo", icon: Expo },
      { name: "Tailwind", icon: Tailwind },
      { name: "React Native", icon: Native },
      { name: "TypeScript", icon: Typescript },
    ],
    gitF: "https://github.com/CodeOnBoat/WiseMaster.git",
  },
  {
    name: "Unpoetic",
    role: "Full-Stack Developer",
    description:
      "Poetry sharing, AI-driven translation and text to speach integration.",
    image: project12,
    technology: [
      { name: "TypeScript", icon: Typescript },
      { name: "Express", icon: Express },
      { name: "NoSQL", icon: NoSQL },
      { name: "React", icon: React },
      { name: "Firebase", icon: Firebase },
      { name: "Node", icon: Node },
    ],
    gitF: "https://github.com/1998KB/poetry-project-front",
    gitB: "https://github.com/1998KB/poetry-project-back",
    link: "https://poetry-e04c9.web.app",
  },
  {
    name: "Rocket Team",
    role: "Full-Stack Developer & Designer & Copywriter",
    description:
      "Multilingual software company that makes websites, phone applications and chatbot integrations.",
    image: project7,
    technology: [
      { name: "TypeScript", icon: Typescript },
      { name: "React", icon: React },
      { name: "Tailwind", icon: Tailwind },
      { name: "Firebase", icon: Firebase },
      { name: "Figma", icon: Figma },
    ],
    gitF: "https://github.com/CodeOnBoat/rocketteam",
    link: "https://www.rocket-team.space/",
  },
  {
    name: "TaskWise",
    role: "Full-Stack Developer",
    description:
      "AI-enhanced task management app with guided projects. I contributed as a full-stack developer.",
    image: project2,
    technology: [
      { name: "TypeScript", icon: Typescript },
      { name: "Node", icon: Node },
      { name: "Express", icon: Express },
      { name: "NoSQL", icon: NoSQL },
      { name: "React", icon: React },
      { name: "CSS", icon: Css },
      { name: "Firebase", icon: Firebase },
    ],
    gitF: "https://github.com/CodeOnBoat/Project-Manager-App-Frontend",
    gitB: "https://github.com/CodeOnBoat/Project-Manager-App-Backend",
    link: "https://taskwise.pro",
  },
  {
    name: "Iconic",
    role: "Full-Stack Developer",
    description:
      "Responsive web solution for a beauty salon, featuring booking, services and directions.",
    image: project3,
    technology: [
      { name: "TypeScript", icon: Typescript },
      { name: "React", icon: React },
      { name: "CSS", icon: Css },
      { name: "Firebase", icon: Firebase },
    ],
    gitF: "https://github.com/CodeOnBoat/iconic-browse-and-beuty",
    link: "https://iconicbrowsandbeauty.se",
  },
  {
    name: "Crypto Wallet",
    role: "Backend Developer",
    description:
      "Secure backend developed with Spring Security for storing and safeguarding cryptocurrencies.",
    image: project4,
    technology: [
      { name: "Java", icon: Java },
      { name: "SpringBoot", icon: Springboot },
    ],
    gitB: "https://github.com/1998KB/cryptoWallet-springBackend",
  },

  {
    name: "Nordic Elite Backoffice",
    role: "Full-Stack Developer",
    description:
      "Football accademy website to book individual and group trainings.",
    image: project11,
    technology: [
      { name: "TypeScript", icon: Typescript },
      { name: "Express", icon: Express },
      { name: "NoSQL", icon: NoSQL },
      { name: "React", icon: React },
      { name: "Firebase", icon: Firebase },
      { name: "Tailwind", icon: Tailwind },
    ],
    gitF: "https://github.com/1998KB/pftbackoffice",
    link: "https://pftbackoffice.web.app",
  },
  {
    name: "Nordic Elite",
    role: "Full-stack ",
    description:
      "Football accademy website to book individual and group trainings.",
    image: project10,
    technology: [
      { name: "Figma", icon: Figma },
      { name: "TypeScript", icon: Typescript },
      { name: "Express", icon: Express },
      { name: "NoSQL", icon: NoSQL },
      { name: "React", icon: React },
      { name: "Firebase", icon: Firebase },
      { name: "Mui", icon: Mui },
      { name: "Node", icon: Node },
    ],
    gitF: "https://github.com/1998KB/profotbolltraning",
    gitB: "https://github.com/1998KB/profotbolltraining-backend",
    link: "https://profotbolltraning-533fd.web.app",
  },
  {
    name: "Join Spline Art",
    role: "3D Designer",
    description:
      "Animated 3D models & design (desktop version) of the welcome page to Spline tool.",
    image: project9,
    technology: [
      { name: "TypeScript", icon: Typescript },
      { name: "React", icon: React },
      { name: "Spline", icon: Spline },
    ],
    link: "https://spline-art.vercel.app",
  },

  {
    name: "Convileo",
    role: "Full-Stack Developer & Designer",
    description:
      "Event search website, groups making and events sharing. Google maps and Stripe integration. ",
    image: project5,
    technology: [
      { name: "TypeScript", icon: Typescript },
      { name: "Express", icon: Express },
      { name: "NoSQL", icon: NoSQL },
      { name: "React", icon: React },
      { name: "Mui", icon: Mui },
      { name: "Firebase", icon: Firebase },
      { name: "Node", icon: Node },
    ],
    link: "https://convileo-cb5be.web.app/",
  },
  {
    name: "Producteers",
    role: "Frontend Developer",
    description: "Software company that offers a wide range of IT services.",
    image: project6,
    technology: [
      { name: "Firebase", icon: Firebase },
      { name: "React", icon: React },
      { name: "Node", icon: Node },
      { name: "TypeScript", icon: Typescript },
    ],
    link: "https://producteers-2f010.web.app",
  },

  {
    name: "NeuroArt",
    role: "Full-Stack Developer & Designer",
    description:
      "AI-driven image generator with authentication, personal profiles, and a shared gallery",
    image: project1,
    technology: [
      { name: "Java", icon: Java },
      { name: "SpringBoot", icon: Springboot },
      { name: "SQL", icon: SQL },
      { name: "TypeScript", icon: Typescript },
      { name: "React", icon: React },
      { name: "CSS", icon: Css },
      { name: "Azure", icon: Azure },
    ],
    gitF: "https://github.com/1998KB/NeuroArt",
    link: "https://drive.google.com/file/d/1IZoZ_ku9NMlmE8tKW2zDpf2KiQOT2NG6/view?usp=sharing",
  },
  {
    name: "Latest Portfolio",
    role: "Full-Stack Developer & Designer",
    description: "Latest portfolio, project list and chatbot integration.",
    image: project16,
    technology: [
      { name: "TypeScript", icon: Typescript },
      { name: "React", icon: React },
      { name: "Firebase", icon: Firebase },
      { name: "Tailwind", icon: Tailwind },
    ],
    gitF: "https://github.com/1998KB/klajdibeqiraj",
    link: "https://www.klajdibeqiraj.com",
  },
  {
    name: "Portfolio II",
    role: "Frontend Developer & Designer",
    description: "Second attempt of creating a personal page.",
    image: project15,
    technology: [
      { name: "TypeScript", icon: Typescript },
      { name: "React", icon: React },
      { name: "Firebase", icon: Firebase },
      { name: "Mui", icon: Mui },
    ],
    gitF: "https://github.com/1998KB/personal-page",
    link: "https://personal-page-chi-ochre.vercel.app",
  },
  {
    name: "Portfolio I",
    role: "Frontend Developer & Designer",
    description: "First attempt of creating a personal page.",
    image: project14,
    technology: [
      { name: "TypeScript", icon: Typescript },
      { name: "React", icon: React },
      { name: "Firebase", icon: Firebase },
      { name: "CSS", icon: Css },
    ],
    gitF: "  https://github.com/1998KB/portfolio",
    link: "https://klajdi-beqiraj.netlify.app",
  },
];

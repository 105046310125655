import React, { useState, useEffect } from "react";
import { quotes } from "../../../assets/content/quotes";
import ArrowRightIconBlack from "../../../assets/images/arrowRightBlack.svg";
import ArrowRightIconWhite from "../../../assets/images/arrowRightWhite.svg";
import ArrowLeftIconBlack from "../../../assets/images/arrowLeftBlack.svg";
import ArrowLeftIconWhite from "../../../assets/images/arrowLeftWhite.svg";

export const Quotes = ({ theme }) => {
  const [currentQuoteIndex, setCurrentQuoteIndex] = useState(0);

  const showNextQuote = () => {
    setCurrentQuoteIndex((prevIndex) => (prevIndex + 1) % quotes.length);
  };

  const showPreviousQuote = () => {
    setCurrentQuoteIndex(
      (prevIndex) => (prevIndex - 1 + quotes.length) % quotes.length
    );
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      showNextQuote();
    }, 6000);

    return () => clearInterval(intervalId);
  }, [currentQuoteIndex]);

  return (
    <div className="hidden lg:flex lg:flex-col h-full items-center justify-center relative gap-10 w-full px-8 rounded-3xl rounded-l-none bg-gradient-to-l from-black/70 to-black/10 dark:from-white/70 dark:to-white/25">
      <div className="flex items-center absolute top-10 right-8">
        <button onClick={showPreviousQuote} className="text-white">
          {theme === "dark" ? (
            <img
              src={ArrowLeftIconBlack}
              alt="Arrow left Icon"
              width="25"
              height="25"
              className="hover:scale-125 transition-transform duration-300"
            />
          ) : (
            <img
              src={ArrowLeftIconWhite}
              alt="Arrow left Icon"
              width="25"
              height="25"
              className="hover:scale-125 transition-transform duration-300"
            />
          )}
        </button>
        <button onClick={showNextQuote} className="text-white ml-4">
          {theme === "dark" ? (
            <img
              src={ArrowRightIconBlack}
              alt="Arrow left Icon"
              width="25"
              height="25"
              className="hover:scale-125 transition-transform duration-300"
            />
          ) : (
            <img
              src={ArrowRightIconWhite}
              alt="Arrow left Icon"
              width="25"
              height="25"
              className="hover:scale-125 transition-transform duration-300"
            />
          )}
        </button>
      </div>
      <div className="font-SequelSansLightOblBody text-xl transition-opacity duration-500 ease-in-out">
        {quotes[currentQuoteIndex].content.split("\n").map((line, index) => (
          <p key={index}>{line}</p>
        ))}
      </div>
      <span className="font-SequelSansSemiBoldHead absolute bottom-10 text-2xl font-bold text-right w-full pr-8">
        {quotes[currentQuoteIndex].author}
      </span>
    </div>
  );
};

import React, { useRef, useEffect } from "react";
import SendIconWhite from "../../../assets/images/sendWhite.svg";

export const MessageInput = ({
  message,
  handleMessageChange,
  handleSendMessage,
}) => {
  const textareaRef = useRef(null);

  useEffect(() => {
    textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
  }, [message]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage(message);
    }
  };

  return (
    <div className="flex justify-between gap-2  relative">
      <textarea
        ref={textareaRef}
        value={message}
        onChange={handleMessageChange}
        placeholder="Type your message..."
        className="flex-grow pl-4 pr-12 py-6 border-none dark:text-black focus:outline-none dark:bg-white/50 bg-black/50 rounded-b-3xl"
        rows={1}
        style={{ resize: "none" }}
        onKeyDown={handleKeyDown}
      />
      <button
        onClick={handleSendMessage}
        className="p-2 bg-blue-300 dark:bg-black rounded-full absolute right-4 top-4"
      >
        <img src={SendIconWhite} alt="Send Icon" width="" height="25" />
      </button>
    </div>
  );
};

import React, { useState } from "react";
import { Chat } from "./informations/Chat";
import { Links } from "./informations/Links";
import { ChatCover } from "./informations/ChatCover";

export const Informations = ({ theme }) => {
  const [chatStarted, setChatStarted] = useState(false);
  const handleStartChat = () => {
    setChatStarted(true);
  };

  return (
    <div className="flex flex-col-reverse lg:flex-row gap-2 lg:gap-0 h-full">
      <Links theme={theme} />
      <div className="flex justify-center items-center mx-4 lg:w-full h-[30em] lg:h-[60vh]">
        {chatStarted ? <Chat /> : <ChatCover onStartChat={handleStartChat} />}{" "}
      </div>
    </div>
  );
};

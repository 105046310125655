import { useEffect, useState } from "react";

const useTheme = () => {
  const getUserThemePreference = () => {
    const userTheme = localStorage.getItem("userTheme");
    if (userTheme) {
      return userTheme;
    } else {
      return window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light";
    }
  };

  const [theme, setTheme] = useState(getUserThemePreference);

  useEffect(() => {
    localStorage.setItem("userTheme", theme);
    document.documentElement.classList.toggle("dark", theme === "dark");
  }, [theme]);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "dark" ? "light" : "dark"));
  };

  return { theme, toggleTheme };
};

export default useTheme;

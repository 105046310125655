export const quotes = [
  {
    content: "The only way\nto do great work\nis to love what you do.",
    author: "Steve Jobs",
  },
  {
    content: "Don't watch the clock;\ndo what it does.\nKeep going.",
    author: "Sam Levenson",
  },
  {
    content:
      "Success usually comes\nto those who are too busy\nto be looking for it.",
    author: "Henry David Thoreau",
  },
  {
    content:
      "The only place where\nsuccess comes before work\nis in the dictionary.",
    author: "Vidal Sassoon",
  },
  {
    content:
      "The only limit to our realization\nof tomorrow will be our doubts of today.",
    author: "Franklin D. Roosevelt",
  },
  {
    content: "I find that the harder I work,\nthe more luck I seem to have.",
    author: "Thomas Jefferson",
  },
  {
    content: "Opportunities don't happen.\nYou create them.",
    author: "Chris Grosser",
  },
  {
    content:
      "The only person you are destined to become\nis the person you decide to be.",
    author: "Ralph Waldo Emerson",
  },
  {
    content: "Don’t be afraid to give up the good\nto go for the great.",
    author: "John D. Rockefeller",
  },
  {
    content: "The best way to predict the future\nis to create it.",
    author: "Peter Drucker",
  },
  {
    content: "The only limit to our impact\nis our imagination and commitment.",
    author: "Tony Robbins",
  },
  {
    content:
      "It does not matter how slowly you go\nas long as you do not stop.",
    author: "Confucius",
  },
  {
    content: "The way to get started\nis to quit talking and begin doing.",
    author: "Walt Disney",
  },
  {
    content: "Believe you can and you're halfway there.",
    author: "Theodore Roosevelt",
  },
  {
    content:
      "Success is stumbling from failure to failure\nwith no loss of enthusiasm.",
    author: "Winston S. Churchill",
  },
  {
    content:
      "The only place where success comes before work\nis in the dictionary.",
    author: "Vidal Sassoon",
  },
  {
    content:
      "The only man who never makes a mistake\nis the man who never does anything.",
    author: "Theodore Roosevelt",
  },
];

import React from "react";
import SunIcon from "../../assets/images/sun.svg";
import MoonIcon from "../../assets/images/moon.svg";

export const Presentation = ({ theme, toggleTheme }) => {
  return (
    <div className="mr-4 relative w-full px-6 p-2 rounded-3xl rounded-l-none bg-gradient-to-l  from-black/70 to-black/10 dark:from-white/60 dark:to-white/20 ">
      <div className="font-SequelSansBlackOblDisp text-3xl lg:text-6xl my-4 ">
        KLAJDI BEQIRAJ
      </div>
      <div className="font-SequelSansLightHead mb-4 text-sm lg:text-2xl">
        POLYGLOT FULLSTACK DEVELOPER & DESIGNER
      </div>
      <button className="absolute top-3 right-3" onClick={toggleTheme}>
        {theme === "dark" ? (
          <img src={SunIcon} alt="Sun Icon" width="30" height="30" />
        ) : (
          <img src={MoonIcon} alt="Moon Icon" width="30" height="30" />
        )}
      </button>
    </div>
  );
};
